<template>
    <v-card>
      <v-card-text>
        <DataTableComponent
          :headers="headers"
          :items="items"
          :total_registros="total_registros"
          :show_loading="isLoading"
          @paginar="paginar"
        >
          <template v-slot:item.codigo="{ item }">
            {{ item.codigo.toString().slice(0, -2) }}
          </template>
          <template v-slot:item.institucion="{ item }">
            {{ item.institucion.nombre }}
          </template>
          <template v-slot:item.proveedor="{ item }">
            {{ item.proveedor.nombre_comercial }}
          </template>
          <template v-slot:item.fecha_autorizacion="{ item }">
            <span class="primary--text">
              {{ moment(item.fecha_autorizacion).format("DD/MM/YYYY") }}
            </span>
          </template>
          <template v-slot:item.total="{ item }">
            <span class="primary--text">
              ${{ Number(item.total).toFixed(2) }}
            </span>
          </template>
          <template v-slot:item.total_aceptado="{ item }">
            <span class="primary--text">
              ${{ item.total_aceptado }}
            </span>
          </template>
          <template v-slot:item.tiempo_restante="{ item }">
            <span class="primary--text">
              <TiempoEntrega
                :fecha="item.fecha_entrega"
                :fallback="item.solicitud.id_tipo_solicitud === 1 ? 'No existe una fecha de entrega' : 'No existe un agendamiento'"
              />
            </span>
          </template>
          <template v-slot:item.estado="{ item }">
            <v-chip v-if="item.estado" label dark class="mt-1" :color="item.estado.color">
              {{ item.estado.nombre || "" }}
            </v-chip>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  style="order: 50"
                  color="primary"
                  @click="abrirDetalle(item)"
                  icon
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver detalles</span>
            </v-tooltip>
          </template>
        </DataTableComponent>
      </v-card-text>
    </v-card>
  </template>
<script>

import DataTableComponent from '@/components/DataTableComponent.vue';
import TiempoEntrega from '../CMOrdenCompra/components/TiempoEntrega.vue';

export default {
    name: 'TabsOrdenesCompra',
    components: { DataTableComponent,TiempoEntrega },
    data: () => ({
      ordenSeleccionada: null,
    }),
    props: {
      headers: {
        type: Array,
      },
      items: {
        type: Array,
      },
      total_registros: {
        type: Number,
      },
      isLoading: {
        type: Boolean,
      },
      paginar: {
        type: Function,
      },

    },
    methods: {
      abrirDetalle(item) {
        if(this.haveRole("ROLE_PROVEEDOR")){
          this.$router.push(
          `/detalle-orden-compra/${item.id}`);
        }else{
          this.$router.push(
            `/cm-cumplimiento-de-solicitud/productos/${item.solicitud.id}/${item.id}`
          );
        }
      },
    }
  }
  </script>
  